import React from 'react'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import ArticleLayout from '../components/article-layout'
import Layout from '../components/layout'
import SEO from '../components/seo'

const InstruoPrivacyPolicy = (props) => {
  return (
    <Layout>
      <SEO title='Instruo Privacy Policy' />
      <ArticleLayout>
        <Typography variant='h1'>Privacy Policy</Typography>
        <Typography variant='body1'>
          This privacy policy describes how Tomas Sjösten uses and protects information that you may give when using our websites, games or applications. Tomas Sjösten is committed to ensuring that your privacy is protected. You can be assured that any information provided by you will only be used in accordance with this privacy statement. Tomas Sjösten may change this privacy policy from time to time by updating this page. This policy is effective from 3rd of June, 2020.
        </Typography>
        <Typography variant='body1'>
          The games, mobile applications and webservices are provided by Tomas Sjösten, <Link href='mailto:tosj.utv@gmail.com'>tomas.sjosten@gmail.com</Link>.
        </Typography>

        <Typography variant='body1'>
          This Privacy Policy is a complement to our Terms and Condition. In the event of discrepancy between the Privacy Policy and the Terms, the Privacy Policy shall prevail. If you don´t agree with this Privacy Policy or the <Link href='/instruo-terms-condition'>Terms and Conditions</Link>, you should not use the sites and services. If you have any questions please contact me at <Link href='mailto:tomas.sjosten@gmail.com'>tomas.sjosten@gmail.com</Link>
        </Typography>

        <Typography variant='h2'>About the general data protection regulation</Typography>
        <Typography variant='body1'>
          I handle all personal data in accordance to the European General Data Protection Regulation (GDPR). If you believe that I have handled your personal data incorrectly please contact the responsible authority in Sweden,
          <br />Datainspektionen
          <br />Box 8114
          <br />104 20 Stockholm
          <br />Sweden
        </Typography>


        <Typography variant='h2'>The personal data that I collect and why I do so</Typography>
        <Typography variant='body1'>
          My games, applications or web services do not collect personal data today. In some of my apps the user can create a user profile with a name or add content within the application. This information is only stored locally on the actual device and is not collected or stored by me. In some applications there are functionality for uploading various types of information about yourself or others, such as name, gender, contact information, pictures, own notes and so on. You may also upload information about yourself such as medical information, medications, health data and information to be shared on social media platforms. You upload all such information for your own sake only. I do not process such information for my own sake and will therefore not be the controller of such data. You can always change or delete such information yourself. Some of my games, applications or websites may collect anonymous data of usage to help us improve my games, application or websites. This data may contain information about how and where the applications, games and websites is used, but it is not associated with any personal data. It could also contain information about your device, language preferences and display resolution. If you contact me, e.g. by email, I may store your name and contact information as long as it has relevance to me. When we do not need your name and contact information any longer, we will remove it.
        </Typography>

        <Typography variant='body1'>
          Link to privacy policy of third party service providers used by my websites, games or applications
          <ul>
            <li><Link href="https://www.google.com/policies/privacy/">Google Play Services</Link></li>
            <li><Link href='https://firebase.google.com/policies/analytics'>Google Analytics for Firebase</Link></li>
            <li><Link href='https://firebase.google.com/support/privacy/'>Firebase Crashlytics</Link></li>
          </ul>
        </Typography>




        <Typography variant='h2'>Storage of personal data</Typography>
        <Typography variant='body1'>
          The period of time when I store personal data is dependent on what legal ground we have for my processing. The personal data will be stored until the legal ground for the processing has ceased. Data is deleted from my records when it is no longer needed within a reasonable timeframe.
        </Typography>




        <Typography variant='h2'>Security</Typography>
        <Typography variant='body1'>
          I will take all such measures that reasonably may be requested for the ensurance of your personal data being processed securely and in accordance with this Privacy Policy. Unfortunately, the transmission of any information via the internet is never entirely secure. Any transmission is therefore at own risk. In the event of a security breach, Tomas Sjösten will of course take action to remediate the issue as soon as possible.
          <ul>
            <li>Controlling your personal data, sharing of personal data to third parties</li>
            <li>I will not sell, distribute or lease any collected information to third parties unless I have your permission or are required by law to do so. If you believe that I hold any information about you that is incorrect or incomplete, please contact me as soon as possible. I will promptly correct any information found to be incorrect.</li>
            <li>Removing personal data; I will remove any personal data about you that I hold if you request so.</li>
            <li>I only store personal data that has relevance to me. Any personal data that no longer has any relevance to me will be removed within one year.</li>
          </ul>
        </Typography>



        <Typography variant='h2'>COPPA (Children Online Privacy Protection Act)</Typography>
        <Typography variant='body1'>
          My applications and services directed to children comply with COPPA (Children Online Privacy Protection Act). I do not knowingly collect personal information from children under the age of 13. If I would receive personal information by a child under the age of 13, I will promptly remove such information.
        </Typography>

        <Typography variant='h2'>Cookies</Typography>
        <Typography variant='body1'>
          My websites uses cookies to help me understand how the websites is used and how it can be improved.
        </Typography>

        <Typography variant='h2'>Links to third party</Typography>
        <Typography variant='body1'>
           My websites, games and applications may contain links to other websites. However, once you have used these links to leave our website, game or application, you should note that I do not have any control over that other website or service. Therefore, I cannot be responsible for the protection and privacy of any information that you provide whilst visiting such websites and such websites are not governed by this privacy policy.
        </Typography>

        <Typography variant='h2'>Changes to This Privacy Policy</Typography>
        <Typography variant='body1'>
          I may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. I will notify you of any changes by posting the new Privacy Policy on this page.
        </Typography>

        <Typography variant='h2'>Contact me</Typography>
        <Typography variant='body1'>
          If you have any questions or suggestions about this Privacy Policy, do not hesitate to contact me at <Link href='mailto:tomas.sjosten@gmail.com'>tomas.sjosten@gmail.com</Link>.
        </Typography>
      </ArticleLayout>
    </Layout>
  )
}

export default InstruoPrivacyPolicy
